@media only screen and (min-width: 1240px) {
    /* Page =================================================== */

    /* Typography =================================================== */

    /* Forms =================================================== */

    /* Buttons =================================================== */

    /* Tables =================================================== */

    /* Layout =================================================== */
    .column-xl-1-1{
		width: 100.0%;
	}
	.column-xl-2-1{
		width: 50.0%;
	}
	.column-xl-3-1{
		width: 33.333%;
	}
	.column-xl-3-2{
		width: 66.666%;
	}
	.column-xl-4-1{
		width: 25.0%;
	}

    /* Header =================================================== */
    
    /* Main =================================================== */

    /* Footer =================================================== */

    /* Pages =================================================== */
}