/* Fonts */
$font-base: 'Montserrat', sans-serif;

/* Variables */
$text: #343644;

$bg-grey: #EDEDED;
$bg-light-grey: #F6F6F6;
$bg-dark-grey: #D6D7DA;

$blue: #39648E;
$green: #7AC943;
$orange: #F99F00;
$red: #C1272D;

/* Mixins */
@mixin hide-text{
    overflow:hidden;
    text-indent:-900em;
    display:block;
}

@mixin floated-list{
    margin:0;
	padding:0;
	li{
		padding:0;
		list-style:none;
		float:left;
	}
}

@mixin clearfix{
	zoom: 1;
	&:before, &:after { 
		content: '.'; 
		display: block; 
		overflow: hidden; 
		visibility: hidden; 
		font-size: 0; 
		line-height: 0; 
		width: 0; height: 0; 
	} 
	&:after{ 
		clear: both; 
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes check {
	0% {
		stroke-dashoffset: -24;
	}
	100% {
		stroke-dashoffset: 0;
	}
}