@import "base";
@import "reset";

/* Page =================================================== */
html { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

body {
	width:100%;
    min-width:300px;
    font-family: $font-base;
    color: $text;
    background: $bg-grey;
}

.red {
	color: $red;
	background-color: #ffecec;
}
.orange {
	color: $orange;
	background-color: #fffadf;
}

#wrapper{
    width:100%;
    overflow:hidden;
    margin-top: calc(54px + 1em);
}

/* Typography =================================================== */
h1, h2, h3, h4, h5, h6 {
    line-height:115%;
    letter-spacing: -0.05em;
    font-weight: 300;
}
h1 {
    font-size: 2.5em;
    margin: 0.25em 0;
    [class^="icon"],
    [class*=" icon"] {
        display: block;
        margin-bottom: 0.5em;
    }
}
h2 {
    font-size: 2em;
    margin: 0.6em 0;
}
h3 {
    font-size: 1.4em;
    margin: 0.5em 0;
}
h4 {
    font-size: 1.3em;
}
h5 {
    font-size: 1.2em;
    margin: 0.5em 0;
}
h6 {
    font-size: 1em;
}

p {

}

a {
    transition: color 200ms, background-color 200ms;
	&:hover{ 
        color: $blue;
    }
	&:active{ }
	&:visited{ }
}

img{
    max-width:100%;
    height:auto;
}

/* Forms =================================================== */
.field{
    margin-bottom:1em;
}

.file-upload {
    text-align: center;
    .field {
        margin-bottom: 0;
    }   
    input {
        visibility: hidden;
        position: absolute;
        left: -999em;
    }    
    .dummy-input {
        transition: 200ms;
        border: 3px dashed #666;
        opacity: 0.5;
        display: block;
        padding: 3em 1em;
        font-weight: 600;
        color: #888;
        svg {
            display: block;
            margin: 0 auto 0.2em;
            font-size: 3em;
        }
        &:hover {
            opacity: 1;
        }
    }
    button {
        margin-top: 1em;
    }
    &.has-file {
        .dummy-input {
            opacity: 1;
            color: #222;
        }
    }
}

input,
textarea{
    font:inherit;
    width:100%;
    border: none;
    line-height: 120%;
    background: #fff;
    padding: 0.75em 1em;
    font-size: 24px;
}

input[type="checkbox"],
input[type="radio"]{
    width:auto;
}

textarea{
    height:10em;
}

select {
    background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNi4zMSAxNi4zMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYuMzEgMTYuMzEiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBvbHlnb24gcG9pbnRzPSI4LjE2LDEzLjEgMC4zOSw1LjM0IDIuNTEsMy4yMSA4LjE2LDguODYgMTMuOCwzLjIxIDE1LjkyLDUuMzQgCSIvPg0KPC9nPg0KPC9zdmc+DQo=") right 1em top 50% no-repeat;
    background-size: 16px;
    appearance: none;
    font:inherit;
    width:100%;
    border: none;
    line-height: 120%;
    padding: 0.75em 2em 0.75em 1em;
    font-size: 24px;
}

select.dark {
    background: $text url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTYuMzEgMTYuMzEiPg0KCTxwb2x5Z29uIGZpbGw9IiNmZmYiIHBvaW50cz0iOC4xNiwxMy4xIDAuMzksNS4zNCAyLjUxLDMuMjEgOC4xNiw4Ljg2IDEzLjgsMy4yMSAxNS45Miw1LjM0IAkiLz4NCjwvc3ZnPg0K") right 1em top 50% no-repeat;
    background-size: 16px;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    outline: none;
    width: auto;
    font-size: 16px;
}

.checkbox {
    input {
        position: absolute;
        left: -999em;
    }
    .cb {
        transition: 200ms;
        display: block;
        background: $bg-dark-grey;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        line-height: 27px;
        text-align: center;
        color: #fff;
        svg {
            visibility: hidden;
            stroke-dasharray: 24;
            stroke-dashoffset: -24;
            vertical-align: middle;
            margin: 5px;
        }
    }
    input:checked ~ .cb {
        background: $green;
        svg {
            transition: 200ms;
            visibility: visible;
            stroke-dashoffset: 0;
        }
    }
}

.select-all {
    background: $bg-dark-grey;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    &__indicator {
        display: block;
        background: $green;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        margin: 5px;
    }
    svg {
        stroke-dasharray: 24;
        stroke-dashoffset: -24;
        vertical-align: middle;
        animation: check 200ms forwards;
        background: #7AC943;
        height: 30px;
        width: 30px;
        border-radius: 20px;
        padding: 6px 5px 4px;
    }
}

.field-validation-error{
    border-radius: 0 0 5px 5px;
    font-size: 16px;
    display: block;
    font-weight: normal;
    color: $red;
    background-color: rgba($red, 0.1);
    padding: 0.25em 1.1em;
    margin: 0 0.5em;
    text-align: left;
}

.loader {
    text-align: center;
    img {
        animation: spin 1s infinite linear;
        width: 20px;
        height: 20px;
    }
    &--pad {
        padding: 20%;
        img {
            width: 80px;
            height: 80px;
        }
    }
}

.page-error {
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    font-size: 1.5em;
    padding: 20%;
    svg {
        font-size: 3em;
        margin-bottom: 0.25em;
    }
}

/* Icons =================================================== */
[class^="icon"],
[class*=" icon"] {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: top;
}

/* Buttons =================================================== */
[class^="btn"],
[class*=" btn"]{
    transition: color 150ms, background-color 150ms;
    border:none;
    display:inline-block;
    text-decoration:none;
    text-align:center;
    line-height:100%;
    background: $text;
    color: #fff;
    font-family: inherit;
    font-weight: 800;
    font-size: 1em;
    padding: 0.75em 1.5em;
    border-radius: 5px;
    min-width: 200px;
    &:hover {
        background: $blue;
    }
    &__loading-indicator {
        transform: scale(3);
        transition: all 200ms;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -12px;
    }
    &__text {
        transition: all 200ms;
        display: block;
    }
}

.btn--full {
    width: 100%;
}

.btn--secondary {
    background: #fff;
    color: $text;
}

.btn--loading {
    background:#ccc;
}

.btn--destructive {
    background: $red;
    outline: none;
    &:hover {
        background: #DC143C;
    }
}

.btn--primary {
    background: $green;
    outline: none;
    &:hover {
        background: #5ce600;
    }    
}

/* Tables =================================================== */

/* Layout =================================================== */
.container{
	width: 100%;
    max-width:1024px;
	position: relative;
	zoom:1;
    margin:0 auto;
}

[class^="column"],
[class*=" column"]{
    width:100%;
    display: inline;
	float: left;
	padding-left: 1em;
	padding-right: 1em;
    margin-bottom:2em;
    &.pad{
        padding-top:1em;
        padding-bottom:1em;
    }
	&.nopad{
		padding-left: 0;
		padding-right: 0;
	}
    &.nomar{
        margin-bottom:0;
    }
}
.column-s-2-1{
    width:50%;
}
.column-s-3-1{
    width:33.333%;
}
.column-s-3-2{
    width:66.666%;
}

.left {
    float: left;
    margin: 0 1em 1em 0;
}
.right {
    float: right;
    margin: 0 0 1em 1em;
}
.centre{
    text-align:center;
}

.responsive-iframe{
    position: relative;
    padding: 27.5% 0;
    margin:1em 0;
    iframe{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
    }
}

.header {
    select {
        float: right;
    }
}

.sub-header {
    .select-all {
        float: left;
        margin: 0.25em 0.75em 0 0.5em;
    }
    select {
        float: right;
        margin-top: -0.25em;
    }
}

/* Header =================================================== */
#header-main {
    position:fixed;
    top:0;
    left:0;
    z-index:20;
    width:100%;
    background: $blue;
    font-weight: 500;
	.logo{
        transition: 200ms;
        color: #fff;
        float: left;
        opacity: 0.2;
        &:hover {
            opacity: 0.7;
        }
        svg {
            fill:  currentColor;
            margin: 6px 1em;
            height: 40px;
            width: 40px;
            display: block;
        }
	}
	#main-nav{
        float:right;
		.sliding-nav {
            margin:0;
            padding:0;
            display:flex;
            justify-content: space-between;
            li {
                display:flex;
				a {
                    display:block;
                    padding:1em;
                    text-decoration:none;
                    color:#fff;
                    &:hover{
                    }
				}
                &.on{
                    a{
                    }
                }
            }
        }
	}
}

/* Main =================================================== */
#main{
    @include clearfix;
}

/* Footer =================================================== */
#footer-main {
    
}

/* Pages =================================================== */
.login {
    text-align: center;
    min-height: 80vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .logo {
        fill: $blue;
        width: 5em;
        height: 5em;
    }
    form {
        margin: 2em auto;
        width: 600px;
        max-width: 100%;
        button {
            margin-top: 2em;
        }
    }
}

.centre-list {
    &__item {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        transition: 200ms;
        display: block;
        position: relative;
        background: #fff;
        padding: 1em 1em 1em calc(1em + 20px);
        margin-bottom: 1em;
        text-decoration: none;
        color: $text;
        &:hover {
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
            span.btn {
                background: $blue;
            }
        }
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 100%;
            top: 0;
            left: 0;
            box-shadow: inset -14px 0 8px -8px rgba(0, 0, 0, 0.15);
        }
        h2 {
            margin: 0 0 0.5em;
            .icon-house {
                margin-right: 0.25em;
                flex: 0 0 auto;
            }
        }
        &--green:before {
            background: $green;
        }
        &--orange:before {
            background: $orange;
        }
        &--red:before {
            background: $red;
        }
        &--disabled {
            opacity: 0.2;
        }
        &--new {
            transition: 200ms;
            opacity: 0.5;
            background: none;
            border: 3px dashed #666;
            text-align: center;
            padding: 1em;
            .centre-list__item__heading {
                font-size: 2em;
                padding: 0;
                .icon-house {
                    display: none;
                    margin-right: 0.25em;
                    flex: 0 0 auto;
                }
                input {
                    padding: 0;
                    background: none;
                    font-size: 1em;
                    line-height: 115%;
                    letter-spacing: -0.05em;
                    font-weight: 300;
                    outline: none;
                }
            }
            .btn--secondary {
                font-size: 1rem;
                min-width: 0;
                width: auto;
                padding: 0.75em;
                flex: 0 0 40px;
            }
            .form-inner {
                max-height: 0;
                transition: 250ms;
                overflow: hidden;
                button {
                    margin-bottom: 1em;
                }
            }
            &:before {
                display: none;
            }
            &.active,
            &:hover {
                opacity: 1;
                box-shadow: none;
                .btn--secondary {
                    background: $blue;
                    color: #fff;
                }
            }
            &.active {
                .form-inner {
                    max-height: 600px;
                }
            }
        }
    }
}

.centre-details {
    padding: 0;
}

.centre-detail {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    transition: 200ms;
    position: relative;
    margin: 1em;
    background: #fff;
    text-align: center;
    padding: 1em 0 1.5em;
    border-top-width: 14px;
    border-top-style: solid;
    order: 1;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
        .btn {
            background: $blue;
        }
    }
    &--open {
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
    &--green {
        border-color: $green;
    }
    &--orange {
        border-color: $orange;
    }
    &--red {
        border-color: $red;
    }
    &__heading {
        display: block;
        font-size: 1em;
    }
    &__value {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5em;
        font-weight: 200;
        line-height: 100%;
        height: 170px;
        letter-spacing: -0.07em;
    }
    .btn {
        min-width: 0;
    }
    &__arrow {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2em;
        line-height: 2em;
        background: white;
        text-align: center;
        svg {
            font-size: 1.2em;
        }
    }
    .hrs-mins {
        display: block;
        line-height: 100%;
        font-size: 0.8em;
        span {
            vertical-align: top;
            display: inline-block;
            padding: 0 0.1em;
            small {
                display: block;
                font-size: 0.3em;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -0.05em;
            }
        }
    }
}

.centre-expanded-detail {
    order: 2;
    flex: 1 1 100%;
    background: #fff;
    padding: 2em;
    margin: 1em;
    table {
        width: 100%;
        th, td {
            padding: 0.25em 0 0.25em 1em;
            border-bottom: 1px solid $bg-grey;
            vertical-align: middle;
            &:first-child {
                width: 50%;
                padding-left: 0;
            }
        }
        th {
            font-weight: normal;
            font-size: 14px;
            text-align: left;
        }
        td {
            font-size: 14px;
            font-weight: 600;
            strong {
                font-weight: 800;
                font-size: 20px;
            }
        }
    }
}

.centre-users {
    clear: both;
}

.user {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    transition: 200ms;
    background: #fff;
    padding: 0.5em;
    margin-bottom: 0.5em;
    overflow: hidden;
    display: block;
    &--disabled {
        opacity: 0.2;
    }
    &:hover {
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
    }
    .checkbox {
        margin-top: 0.5em;
        margin-right: 0.75em;
        float: left;
    }
    &__name {
        font-weight: 800;
        display: block;
        padding-right: 30px;
        svg {
            margin-top: -3px;
            vertical-align: middle;
        }
    }
    &__position {
        font-weight: 300;
        display: block;
        padding-right: 30px;
    }
    &__delete {
        float: right;
        color: $text;
        text-align: center;
        line-height: 100%;
        font-size: 1.5em;
        margin-top: -1.5em;
        &:hover {
            color: $red;
        }
    }
    &__edit {
        float: right;
        color: $text;
        text-align: center;
        font-size: 1.5em;
        margin-top: -1.5em;
        line-height: 100%;
        margin-right: 30px;
        &:hover {
            color: $blue;
        }    
    }
    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        &:hover {
            color: $red;
        }
    }
    &--edit {
        position: relative;
        .form-inner {
            text-align: center;
            padding: 1em;
            font-size: 1rem;
            .row:first-child {
                padding-top: 1em;
            }
            .two-field {
                .field {
                    input,
                    select {
                        background-color: $bg-grey;
                    }
                }
            }
            button {
                margin-bottom: 1em;
            }
        }    
    }
    &--new {
        transition: 200ms;
        opacity: 0.5;
        background: none;
        border: 3px dashed #666;
        text-align: center;
        padding: 1em;
        .btn--secondary {
            border-radius: 30px;
            padding: 5px 0 0;
            margin-right: 0.5em;
            flex: 0 0 30px;
            min-width: 0;
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .user__name {
            font-size: 1em;
            padding: 0;
            margin-right: 0;
            input {
                padding: 0;
                background: none;
                font-size: 1em;
                font-weight: 800;
                outline: none;
            }
        }
        .form-inner {
            max-height: 0;
            transition: 250ms;
            overflow: hidden;
            font-size: 1rem;
            .row:first-child {
                padding-top: 1em;
            }
            button {
                margin-bottom: 1em;
            }
        }
        &:before {
            display: none;
        }
        &.active,
        &:hover {
            opacity: 1;
            box-shadow: none;
            .btn--secondary {
                background: $blue;
                color: #fff;
            }
        }
        &.active {
            .form-inner {
                max-height: 600px;
            }
        }
    }
}

.alert {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 101;
    transform: translateX(-50%);
    background: white;
    display: flex;
    align-items: center;
    border-left: 3em solid;
    width: 90%;
    max-width: 500px;
    &--success {
        border-left-color: $green;
    }
    
    &--danger {
        border-left-color: $red
    }
    svg {
        flex: 0 0 3em;
        padding: 1em;
        height: 3em;
        width: 3em;
        color: #fff;
        margin-left: -3em;
    }
    span {
      padding: 0.5em 1em;
      flex: 1 1 auto;
      text-align: left;
    }
}

.donut-chart {
    display: flex;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0;
    align-items: center;
    font-weight: 400;
    position: relative;
    .logo {
        width: 50px;
        height: 50px;
        top: 47px;
        left: 50px;
        position: absolute;
        fill: $bg-dark-grey;
    }
    .pie-legend {
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            margin: 0.2em 0;
            span {
                width: 0.7em;
                height: 0.7em;
                border-radius: 1em;
                margin: 0.25em 0.25em 0 0;
                float: left;
            }
            &:nth-child(1) span {
                background-color: $green;
            }
            &:nth-child(2) span {
                background-color: $red;
            }
            &:nth-child(3) span {
                background-color: $orange;
            }
        }
    }
}

.new-report {
    border: 3px dashed #666;
    text-align: center;
    padding: 1em;

    .quill {
        text-align: left;
        background: #fff;
        .ql-container {
            height: 250px;
            h1, h2, h3, p, ul, ol {
                margin-bottom: 0.5em;
                padding: 0;
            }
        }
    }
}

.overlay {
    transition: 350ms;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    transform: translateX(100%);
    background: white;
    display: flex;
    flex-flow: column;
    &.open {
        transform: translateX(0);
    }
    &__header {
        margin-top: 54px;
        padding: 1em 2em;
        border-bottom: 1px solid $bg-dark-grey;
        flex: 0 0;
        h2 {
            margin: 0;
        }
        select { 
            float: right;
            margin: 5px 1em 0 0;
        }
        button {
            float: right;
            min-width: 0;
            background: none;
            color: $text;
            font-size: 1.5em;
            padding: 0.575em;
            margin-right: -0.5em;
            &:hover {
                color: $red;
                background: none;
            }
        }
    }
    &__content {
        flex: 1 1 auto;
        overflow: auto;
        table {
            text-align: left;
            font-size: 0.9rem;
            th, td {
                padding: 0.5em 1em;
                border-right: 1px solid #eee;
            }
            thead {
                tr {
                    border-bottom: 1px solid $bg-dark-grey;
                }
                th {
                    vertical-align: bottom;
                    color: $blue;
                }
            }
            tbody tr {
                transition: 150ms;
                &:nth-child(odd) {
                    background: #fafafa;
                }
                &:hover {
                    background: #f4f4f4;
                }
                td {
                    white-space: pre;
                    overflow: hidden;
                    max-width: 200px;
                }
            }
        }
    }
}

.report {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    padding: 0 1em;
    background: #fff;
    overflow: hidden;
}

.report-buttons {
    float: right;
    margin-top: 18px;
    button {
        min-width: 0;
        margin-left: 10px;
    }
}

.inline-space {
	margin-right: 20px;
}