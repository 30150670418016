@media only screen and (min-width: 600px), screen and (max-device-width: 680px) and (min-aspect-ratio: 13/9) {
    /* Page =================================================== */
    .nav-open{
        #wrapper{
            #wrapper-inner{
                transform: none;
            }
        }
    }

    /* Typography =================================================== */
    h1 {
        font-size: 3em;
        [class^="icon"],
        [class*=" icon"] {
            font-size: 0.7em;
            vertical-align: baseline;
            margin: 0;
            display: inline-block;
        }
    }
    h3 {
        font-size: 1.8em;
    }

    /* Forms =================================================== */
    select.dark {
        padding: 0.75em 3.5em 0.75em 1.5em;
        &.header-select {
            margin-top: 1em;
        }
    }

    /* Buttons =================================================== */

    /* Tables =================================================== */

    /* Layout =================================================== */
    .column-m-1-1{
        width:100.0%;
    }
    .column-m-2-1{
        width:50.0%;
    }
    .column-m-3-1{
        width:33.333%;
    }
    .column-m-3-2{
        width:66.666%;
    }
    .column-m-4-1{
        width:25.0%;
    }

    .header {
        select {
            margin-top: 0.4em;
        }
    }
    
    /* Main =================================================== */

    /* Footer =================================================== */

    /* Pages =================================================== */
    .centre-list {
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem 2rem 2rem calc(2rem + 40px);
            &:before {
                width: 40px;
            }
            h2 {
                margin: 0 1em 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .btn {
                width: auto;
            }
            &--new {
                display: block;
                padding: 0;
                .centre-list__item__heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 2rem 2rem 2rem calc(2rem + 37px);
                    .icon-house {
                        display: block;
                    }
                }
                .form-inner {
                    padding: 0 2rem 0 calc(2rem + 37px);
                    button {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }

    .centre-details {
        display: flex;
        flex-flow: row wrap;
        padding: 0.5em;
    }

    .centre-detail {
        flex: 1 1 33.3%;
        max-width: calc(33.3% - 1em);
        margin: 0.5em;
        &:nth-of-type(n+4) {
            border-bottom-width: 14px;
            border-bottom-style: solid;
            border-top: none;
            order: 3;
            .centre-detail__arrow {
                top: auto;
                bottom: 100%;
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .centre-expanded-detail {
        margin: 0.5em;
    }

    .user {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        .checkbox {
            flex: 0 0;
            margin: 0 0.5em 0 0;
        }
        &__name {
            flex: 1 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 1em;
            
        }
        &__position {
            flex: 1 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 1em;
        }
        &__delete {
            flex: 0 0 30px;
            margin-top: 0;
            font-size: 1em;
        }
        &__edit {
            font-size: 1em;
            margin-right: 10px;
            margin-top: 0;    
        }
        &--edit {
            display: block;
            .form-inner {
                padding: 2em 1em 0 1em;
                button {
                    margin-bottom: 2rem;
                }
            }   
        }
        &--new {
            display: block;
            padding: 0;
            .user__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.5em;
                .icon-house {
                    display: block;
                }
            }
            .form-inner {
                padding: 0 1em 0 calc(1em + 30px);
                button {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .new-report {
        padding: 2em;
    }

    .report {
        padding: 1em 2em;
    }
}
