/* Imports */
/* Fonts */
/* Variables */
/* Mixins */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes check {
  0% {
    stroke-dashoffset: -24; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes check {
  0% {
    stroke-dashoffset: -24; }
  100% {
    stroke-dashoffset: 0; } }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

::-moz-selection {
  background: #6b959d;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #6b959d;
  color: #fff;
  text-shadow: none; }

a:hover, a:active {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }

.ir br {
  display: none; }

.hidden {
  display: none; }

.js .jsHide {
  display: none; }

.no-js .noJsHide {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before, .clearfix:after, .container:before, .container:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

.clearfix:after, .container:after {
  clear: both; }

.clearfix, .container {
  zoom: 1; }

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

/* =============================================================================
   Print Styles
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
            filter: none !important;
    -ms-filter: none !important; }
  /* Black prints faster: h5bp.com/s */
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  /* Don't show links for images, or javascript/internal links */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/* Page =================================================== */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  width: 100%;
  min-width: 300px;
  font-family: "Montserrat", sans-serif;
  color: #343644;
  background: #EDEDED; }

.red {
  color: #C1272D;
  background-color: #ffecec; }

.orange {
  color: #F99F00;
  background-color: #fffadf; }

#wrapper {
  width: 100%;
  overflow: hidden;
  margin-top: calc(54px + 1em); }

/* Typography =================================================== */
h1, h2, h3, h4, h5, h6 {
  line-height: 115%;
  letter-spacing: -0.05em;
  font-weight: 300; }

h1 {
  font-size: 2.5em;
  margin: 0.25em 0; }
  h1 [class^="icon"],
  h1 [class*=" icon"] {
    display: block;
    margin-bottom: 0.5em; }

h2 {
  font-size: 2em;
  margin: 0.6em 0; }

h3 {
  font-size: 1.4em;
  margin: 0.5em 0; }

h4 {
  font-size: 1.3em; }

h5 {
  font-size: 1.2em;
  margin: 0.5em 0; }

h6 {
  font-size: 1em; }

a {
  -webkit-transition: color 200ms, background-color 200ms;
  transition: color 200ms, background-color 200ms; }
  a:hover {
    color: #39648E; }

img {
  max-width: 100%;
  height: auto; }

/* Forms =================================================== */
.field {
  margin-bottom: 1em; }

.file-upload {
  text-align: center; }
  .file-upload .field {
    margin-bottom: 0; }
  .file-upload input {
    visibility: hidden;
    position: absolute;
    left: -999em; }
  .file-upload .dummy-input {
    -webkit-transition: 200ms;
    transition: 200ms;
    border: 3px dashed #666;
    opacity: 0.5;
    display: block;
    padding: 3em 1em;
    font-weight: 600;
    color: #888; }
    .file-upload .dummy-input svg {
      display: block;
      margin: 0 auto 0.2em;
      font-size: 3em; }
    .file-upload .dummy-input:hover {
      opacity: 1; }
  .file-upload button {
    margin-top: 1em; }
  .file-upload.has-file .dummy-input {
    opacity: 1;
    color: #222; }

input,
textarea {
  font: inherit;
  width: 100%;
  border: none;
  line-height: 120%;
  background: #fff;
  padding: 0.75em 1em;
  font-size: 24px; }

input[type="checkbox"],
input[type="radio"] {
  width: auto; }

textarea {
  height: 10em; }

select {
  background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNi4zMSAxNi4zMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYuMzEgMTYuMzEiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBvbHlnb24gcG9pbnRzPSI4LjE2LDEzLjEgMC4zOSw1LjM0IDIuNTEsMy4yMSA4LjE2LDguODYgMTMuOCwzLjIxIDE1LjkyLDUuMzQgCSIvPg0KPC9nPg0KPC9zdmc+DQo=") right 1em top 50% no-repeat;
  background-size: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font: inherit;
  width: 100%;
  border: none;
  line-height: 120%;
  padding: 0.75em 2em 0.75em 1em;
  font-size: 24px; }

select.dark {
  background: #343644 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTYuMzEgMTYuMzEiPg0KCTxwb2x5Z29uIGZpbGw9IiNmZmYiIHBvaW50cz0iOC4xNiwxMy4xIDAuMzksNS4zNCAyLjUxLDMuMjEgOC4xNiw4Ljg2IDEzLjgsMy4yMSAxNS45Miw1LjM0IAkiLz4NCjwvc3ZnPg0K") right 1em top 50% no-repeat;
  background-size: 16px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  outline: none;
  width: auto;
  font-size: 16px; }

.checkbox input {
  position: absolute;
  left: -999em; }

.checkbox .cb {
  -webkit-transition: 200ms;
  transition: 200ms;
  display: block;
  background: #D6D7DA;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  line-height: 27px;
  text-align: center;
  color: #fff; }
  .checkbox .cb svg {
    visibility: hidden;
    stroke-dasharray: 24;
    stroke-dashoffset: -24;
    vertical-align: middle;
    margin: 5px; }

.checkbox input:checked ~ .cb {
  background: #7AC943; }
  .checkbox input:checked ~ .cb svg {
    -webkit-transition: 200ms;
    transition: 200ms;
    visibility: visible;
    stroke-dashoffset: 0; }

.select-all {
  background: #D6D7DA;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer; }
  .select-all__indicator {
    display: block;
    background: #7AC943;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin: 5px; }
  .select-all svg {
    stroke-dasharray: 24;
    stroke-dashoffset: -24;
    vertical-align: middle;
    -webkit-animation: check 200ms forwards;
            animation: check 200ms forwards;
    background: #7AC943;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    padding: 6px 5px 4px; }

.field-validation-error {
  border-radius: 0 0 5px 5px;
  font-size: 16px;
  display: block;
  font-weight: normal;
  color: #C1272D;
  background-color: rgba(193, 39, 45, 0.1);
  padding: 0.25em 1.1em;
  margin: 0 0.5em;
  text-align: left; }

.loader {
  text-align: center; }
  .loader img {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    width: 20px;
    height: 20px; }
  .loader--pad {
    padding: 20%; }
    .loader--pad img {
      width: 80px;
      height: 80px; }

.page-error {
  color: #C1272D;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-size: 1.5em;
  padding: 20%; }
  .page-error svg {
    font-size: 3em;
    margin-bottom: 0.25em; }

/* Icons =================================================== */
[class^="icon"],
[class*=" icon"] {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  vertical-align: top; }

/* Buttons =================================================== */
[class^="btn"],
[class*=" btn"] {
  -webkit-transition: color 150ms, background-color 150ms;
  transition: color 150ms, background-color 150ms;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 100%;
  background: #343644;
  color: #fff;
  font-family: inherit;
  font-weight: 800;
  font-size: 1em;
  padding: 0.75em 1.5em;
  border-radius: 5px;
  min-width: 200px; }
  [class^="btn"]:hover,
  [class*=" btn"]:hover {
    background: #39648E; }
  [class^="btn"]__loading-indicator,
  [class*=" btn"]__loading-indicator {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transition: all 200ms;
    transition: all 200ms;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -12px; }
  [class^="btn"]__text,
  [class*=" btn"]__text {
    -webkit-transition: all 200ms;
    transition: all 200ms;
    display: block; }

.btn--full {
  width: 100%; }

.btn--secondary {
  background: #fff;
  color: #343644; }

.btn--loading {
  background: #ccc; }

.btn--destructive {
  background: #C1272D;
  outline: none; }
  .btn--destructive:hover {
    background: #DC143C; }

.btn--primary {
  background: #7AC943;
  outline: none; }
  .btn--primary:hover {
    background: #5ce600; }

/* Tables =================================================== */
/* Layout =================================================== */
.container {
  width: 100%;
  max-width: 1024px;
  position: relative;
  zoom: 1;
  margin: 0 auto; }

[class^="column"],
[class*=" column"] {
  width: 100%;
  display: inline;
  float: left;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 2em; }
  [class^="column"].pad,
  [class*=" column"].pad {
    padding-top: 1em;
    padding-bottom: 1em; }
  [class^="column"].nopad,
  [class*=" column"].nopad {
    padding-left: 0;
    padding-right: 0; }
  [class^="column"].nomar,
  [class*=" column"].nomar {
    margin-bottom: 0; }

.column-s-2-1 {
  width: 50%; }

.column-s-3-1 {
  width: 33.333%; }

.column-s-3-2 {
  width: 66.666%; }

.left {
  float: left;
  margin: 0 1em 1em 0; }

.right {
  float: right;
  margin: 0 0 1em 1em; }

.centre {
  text-align: center; }

.responsive-iframe {
  position: relative;
  padding: 27.5% 0;
  margin: 1em 0; }
  .responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.header select {
  float: right; }

.sub-header .select-all {
  float: left;
  margin: 0.25em 0.75em 0 0.5em; }

.sub-header select {
  float: right;
  margin-top: -0.25em; }

/* Header =================================================== */
#header-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  background: #39648E;
  font-weight: 500; }
  #header-main .logo {
    -webkit-transition: 200ms;
    transition: 200ms;
    color: #fff;
    float: left;
    opacity: 0.2; }
    #header-main .logo:hover {
      opacity: 0.7; }
    #header-main .logo svg {
      fill: currentColor;
      margin: 6px 1em;
      height: 40px;
      width: 40px;
      display: block; }
  #header-main #main-nav {
    float: right; }
    #header-main #main-nav .sliding-nav {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between; }
      #header-main #main-nav .sliding-nav li {
        display: flex; }
        #header-main #main-nav .sliding-nav li a {
          display: block;
          padding: 1em;
          text-decoration: none;
          color: #fff; }

/* Main =================================================== */
#main {
  zoom: 1; }
  #main:before, #main:after {
    content: '.';
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0; }
  #main:after {
    clear: both; }

/* Footer =================================================== */
/* Pages =================================================== */
.login {
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  .login .logo {
    fill: #39648E;
    width: 5em;
    height: 5em; }
  .login form {
    margin: 2em auto;
    width: 600px;
    max-width: 100%; }
    .login form button {
      margin-top: 2em; }

.centre-list__item {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: 200ms;
  transition: 200ms;
  display: block;
  position: relative;
  background: #fff;
  padding: 1em 1em 1em calc(1em + 20px);
  margin-bottom: 1em;
  text-decoration: none;
  color: #343644; }
  .centre-list__item:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15); }
    .centre-list__item:hover span.btn {
      background: #39648E; }
  .centre-list__item:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset -14px 0 8px -8px rgba(0, 0, 0, 0.15); }
  .centre-list__item h2 {
    margin: 0 0 0.5em; }
    .centre-list__item h2 .icon-house {
      margin-right: 0.25em;
      flex: 0 0 auto; }
  .centre-list__item--green:before {
    background: #7AC943; }
  .centre-list__item--orange:before {
    background: #F99F00; }
  .centre-list__item--red:before {
    background: #C1272D; }
  .centre-list__item--disabled {
    opacity: 0.2; }
  .centre-list__item--new {
    -webkit-transition: 200ms;
    transition: 200ms;
    opacity: 0.5;
    background: none;
    border: 3px dashed #666;
    text-align: center;
    padding: 1em; }
    .centre-list__item--new .centre-list__item__heading {
      font-size: 2em;
      padding: 0; }
      .centre-list__item--new .centre-list__item__heading .icon-house {
        display: none;
        margin-right: 0.25em;
        flex: 0 0 auto; }
      .centre-list__item--new .centre-list__item__heading input {
        padding: 0;
        background: none;
        font-size: 1em;
        line-height: 115%;
        letter-spacing: -0.05em;
        font-weight: 300;
        outline: none; }
    .centre-list__item--new .btn--secondary {
      font-size: 1rem;
      min-width: 0;
      width: auto;
      padding: 0.75em;
      flex: 0 0 40px; }
    .centre-list__item--new .form-inner {
      max-height: 0;
      -webkit-transition: 250ms;
      transition: 250ms;
      overflow: hidden; }
      .centre-list__item--new .form-inner button {
        margin-bottom: 1em; }
    .centre-list__item--new:before {
      display: none; }
    .centre-list__item--new.active, .centre-list__item--new:hover {
      opacity: 1;
      box-shadow: none; }
      .centre-list__item--new.active .btn--secondary, .centre-list__item--new:hover .btn--secondary {
        background: #39648E;
        color: #fff; }
    .centre-list__item--new.active .form-inner {
      max-height: 600px; }

.centre-details {
  padding: 0; }

.centre-detail {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: 200ms;
  transition: 200ms;
  position: relative;
  margin: 1em;
  background: #fff;
  text-align: center;
  padding: 1em 0 1.5em;
  border-top-width: 14px;
  border-top-style: solid;
  order: 1;
  cursor: pointer; }
  .centre-detail:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15); }
    .centre-detail:hover .btn {
      background: #39648E; }
  .centre-detail--open {
    box-shadow: none; }
    .centre-detail--open:hover {
      box-shadow: none; }
  .centre-detail--green {
    border-color: #7AC943; }
  .centre-detail--orange {
    border-color: #F99F00; }
  .centre-detail--red {
    border-color: #C1272D; }
  .centre-detail__heading {
    display: block;
    font-size: 1em; }
  .centre-detail__value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    font-weight: 200;
    line-height: 100%;
    height: 170px;
    letter-spacing: -0.07em; }
  .centre-detail .btn {
    min-width: 0; }
  .centre-detail__arrow {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2em;
    line-height: 2em;
    background: white;
    text-align: center; }
    .centre-detail__arrow svg {
      font-size: 1.2em; }
  .centre-detail .hrs-mins {
    display: block;
    line-height: 100%;
    font-size: 0.8em; }
    .centre-detail .hrs-mins span {
      vertical-align: top;
      display: inline-block;
      padding: 0 0.1em; }
      .centre-detail .hrs-mins span small {
        display: block;
        font-size: 0.3em;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.05em; }

.centre-expanded-detail {
  order: 2;
  flex: 1 1 100%;
  background: #fff;
  padding: 2em;
  margin: 1em; }
  .centre-expanded-detail table {
    width: 100%; }
    .centre-expanded-detail table th, .centre-expanded-detail table td {
      padding: 0.25em 0 0.25em 1em;
      border-bottom: 1px solid #EDEDED;
      vertical-align: middle; }
      .centre-expanded-detail table th:first-child, .centre-expanded-detail table td:first-child {
        width: 50%;
        padding-left: 0; }
    .centre-expanded-detail table th {
      font-weight: normal;
      font-size: 14px;
      text-align: left; }
    .centre-expanded-detail table td {
      font-size: 14px;
      font-weight: 600; }
      .centre-expanded-detail table td strong {
        font-weight: 800;
        font-size: 20px; }

.centre-users {
  clear: both; }

.user {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: 200ms;
  transition: 200ms;
  background: #fff;
  padding: 0.5em;
  margin-bottom: 0.5em;
  overflow: hidden;
  display: block; }
  .user--disabled {
    opacity: 0.2; }
  .user:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15); }
  .user .checkbox {
    margin-top: 0.5em;
    margin-right: 0.75em;
    float: left; }
  .user__name {
    font-weight: 800;
    display: block;
    padding-right: 30px; }
    .user__name svg {
      margin-top: -3px;
      vertical-align: middle; }
  .user__position {
    font-weight: 300;
    display: block;
    padding-right: 30px; }
  .user__delete {
    float: right;
    color: #343644;
    text-align: center;
    line-height: 100%;
    font-size: 1.5em;
    margin-top: -1.5em; }
    .user__delete:hover {
      color: #C1272D; }
  .user__edit {
    float: right;
    color: #343644;
    text-align: center;
    font-size: 1.5em;
    margin-top: -1.5em;
    line-height: 100%;
    margin-right: 30px; }
    .user__edit:hover {
      color: #39648E; }
  .user__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
    .user__close:hover {
      color: #C1272D; }
  .user--edit {
    position: relative; }
    .user--edit .form-inner {
      text-align: center;
      padding: 1em;
      font-size: 1rem; }
      .user--edit .form-inner .row:first-child {
        padding-top: 1em; }
      .user--edit .form-inner .two-field .field input,
      .user--edit .form-inner .two-field .field select {
        background-color: #EDEDED; }
      .user--edit .form-inner button {
        margin-bottom: 1em; }
  .user--new {
    -webkit-transition: 200ms;
    transition: 200ms;
    opacity: 0.5;
    background: none;
    border: 3px dashed #666;
    text-align: center;
    padding: 1em; }
    .user--new .btn--secondary {
      border-radius: 30px;
      padding: 5px 0 0;
      margin-right: 0.5em;
      flex: 0 0 30px;
      min-width: 0; }
      .user--new .btn--secondary svg {
        width: 20px;
        height: 20px; }
    .user--new .user__name {
      font-size: 1em;
      padding: 0;
      margin-right: 0; }
      .user--new .user__name input {
        padding: 0;
        background: none;
        font-size: 1em;
        font-weight: 800;
        outline: none; }
    .user--new .form-inner {
      max-height: 0;
      -webkit-transition: 250ms;
      transition: 250ms;
      overflow: hidden;
      font-size: 1rem; }
      .user--new .form-inner .row:first-child {
        padding-top: 1em; }
      .user--new .form-inner button {
        margin-bottom: 1em; }
    .user--new:before {
      display: none; }
    .user--new.active, .user--new:hover {
      opacity: 1;
      box-shadow: none; }
      .user--new.active .btn--secondary, .user--new:hover .btn--secondary {
        background: #39648E;
        color: #fff; }
    .user--new.active .form-inner {
      max-height: 600px; }

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 101;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: white;
  display: flex;
  align-items: center;
  border-left: 3em solid;
  width: 90%;
  max-width: 500px; }
  .alert--success {
    border-left-color: #7AC943; }
  .alert--danger {
    border-left-color: #C1272D; }
  .alert svg {
    flex: 0 0 3em;
    padding: 1em;
    height: 3em;
    width: 3em;
    color: #fff;
    margin-left: -3em; }
  .alert span {
    padding: 0.5em 1em;
    flex: 1 1 auto;
    text-align: left; }

.donut-chart {
  display: flex;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  align-items: center;
  font-weight: 400;
  position: relative; }
  .donut-chart .logo {
    width: 50px;
    height: 50px;
    top: 47px;
    left: 50px;
    position: absolute;
    fill: #D6D7DA; }
  .donut-chart .pie-legend {
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0; }
    .donut-chart .pie-legend li {
      margin: 0.2em 0; }
      .donut-chart .pie-legend li span {
        width: 0.7em;
        height: 0.7em;
        border-radius: 1em;
        margin: 0.25em 0.25em 0 0;
        float: left; }
      .donut-chart .pie-legend li:nth-child(1) span {
        background-color: #7AC943; }
      .donut-chart .pie-legend li:nth-child(2) span {
        background-color: #C1272D; }
      .donut-chart .pie-legend li:nth-child(3) span {
        background-color: #F99F00; }

.new-report {
  border: 3px dashed #666;
  text-align: center;
  padding: 1em; }
  .new-report .quill {
    text-align: left;
    background: #fff; }
    .new-report .quill .ql-container {
      height: 250px; }
      .new-report .quill .ql-container h1, .new-report .quill .ql-container h2, .new-report .quill .ql-container h3, .new-report .quill .ql-container p, .new-report .quill .ql-container ul, .new-report .quill .ql-container ol {
        margin-bottom: 0.5em;
        padding: 0; }

.overlay {
  -webkit-transition: 350ms;
  transition: 350ms;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background: white;
  display: flex;
  flex-flow: column; }
  .overlay.open {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .overlay__header {
    margin-top: 54px;
    padding: 1em 2em;
    border-bottom: 1px solid #D6D7DA;
    flex: 0 0; }
    .overlay__header h2 {
      margin: 0; }
    .overlay__header select {
      float: right;
      margin: 5px 1em 0 0; }
    .overlay__header button {
      float: right;
      min-width: 0;
      background: none;
      color: #343644;
      font-size: 1.5em;
      padding: 0.575em;
      margin-right: -0.5em; }
      .overlay__header button:hover {
        color: #C1272D;
        background: none; }
  .overlay__content {
    flex: 1 1 auto;
    overflow: auto; }
    .overlay__content table {
      text-align: left;
      font-size: 0.9rem; }
      .overlay__content table th, .overlay__content table td {
        padding: 0.5em 1em;
        border-right: 1px solid #eee; }
      .overlay__content table thead tr {
        border-bottom: 1px solid #D6D7DA; }
      .overlay__content table thead th {
        vertical-align: bottom;
        color: #39648E; }
      .overlay__content table tbody tr {
        -webkit-transition: 150ms;
        transition: 150ms; }
        .overlay__content table tbody tr:nth-child(odd) {
          background: #fafafa; }
        .overlay__content table tbody tr:hover {
          background: #f4f4f4; }
        .overlay__content table tbody tr td {
          white-space: pre;
          overflow: hidden;
          max-width: 200px; }

.report {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 0 1em;
  background: #fff;
  overflow: hidden; }

.report-buttons {
  float: right;
  margin-top: 18px; }
  .report-buttons button {
    min-width: 0;
    margin-left: 10px; }

.inline-space {
  margin-right: 20px; }

@media only screen and (min-width: 600px), screen and (max-device-width: 680px) and (min-aspect-ratio: 13 / 9) {
  /* Page =================================================== */
  .nav-open #wrapper #wrapper-inner {
    -webkit-transform: none;
            transform: none; }
  /* Typography =================================================== */
  h1 {
    font-size: 3em; }
    h1 [class^="icon"],
    h1 [class*=" icon"] {
      font-size: 0.7em;
      vertical-align: baseline;
      margin: 0;
      display: inline-block; }
  h3 {
    font-size: 1.8em; }
  /* Forms =================================================== */
  select.dark {
    padding: 0.75em 3.5em 0.75em 1.5em; }
    select.dark.header-select {
      margin-top: 1em; }
  /* Buttons =================================================== */
  /* Tables =================================================== */
  /* Layout =================================================== */
  .column-m-1-1 {
    width: 100.0%; }
  .column-m-2-1 {
    width: 50.0%; }
  .column-m-3-1 {
    width: 33.333%; }
  .column-m-3-2 {
    width: 66.666%; }
  .column-m-4-1 {
    width: 25.0%; }
  .header select {
    margin-top: 0.4em; }
  /* Main =================================================== */
  /* Footer =================================================== */
  /* Pages =================================================== */
  .centre-list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 2rem calc(2rem + 40px); }
    .centre-list__item:before {
      width: 40px; }
    .centre-list__item h2 {
      margin: 0 1em 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .centre-list__item .btn {
      width: auto; }
    .centre-list__item--new {
      display: block;
      padding: 0; }
      .centre-list__item--new .centre-list__item__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem 2rem calc(2rem + 37px); }
        .centre-list__item--new .centre-list__item__heading .icon-house {
          display: block; }
      .centre-list__item--new .form-inner {
        padding: 0 2rem 0 calc(2rem + 37px); }
        .centre-list__item--new .form-inner button {
          margin-bottom: 2rem; }
  .centre-details {
    display: flex;
    flex-flow: row wrap;
    padding: 0.5em; }
  .centre-detail {
    flex: 1 1 33.3%;
    max-width: calc(33.3% - 1em);
    margin: 0.5em; }
    .centre-detail:nth-of-type(n+4) {
      border-bottom-width: 14px;
      border-bottom-style: solid;
      border-top: none;
      order: 3; }
      .centre-detail:nth-of-type(n+4) .centre-detail__arrow {
        top: auto;
        bottom: 100%; }
        .centre-detail:nth-of-type(n+4) .centre-detail__arrow svg {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
  .centre-expanded-detail {
    margin: 0.5em; }
  .user {
    display: flex;
    align-items: center;
    font-size: 1.5em; }
    .user .checkbox {
      flex: 0 0;
      margin: 0 0.5em 0 0; }
    .user__name {
      flex: 1 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 1em; }
    .user__position {
      flex: 1 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 1em; }
    .user__delete {
      flex: 0 0 30px;
      margin-top: 0;
      font-size: 1em; }
    .user__edit {
      font-size: 1em;
      margin-right: 10px;
      margin-top: 0; }
    .user--edit {
      display: block; }
      .user--edit .form-inner {
        padding: 2em 1em 0 1em; }
        .user--edit .form-inner button {
          margin-bottom: 2rem; }
    .user--new {
      display: block;
      padding: 0; }
      .user--new .user__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em; }
        .user--new .user__header .icon-house {
          display: block; }
      .user--new .form-inner {
        padding: 0 1em 0 calc(1em + 30px); }
        .user--new .form-inner button {
          margin-bottom: 2rem; }
  .new-report {
    padding: 2em; }
  .report {
    padding: 1em 2em; } }

@media only screen and (min-width: 980px), screen and (min-device-width: 681px) and (max-device-width: 980px) and (min-aspect-ratio: 13 / 9) {
  /* Page =================================================== */
  /* Typography =================================================== */
  h1 {
    font-size: 4em; }
  h2 {
    font-size: 2.8em; }
  h3 {
    font-size: 2em; }
  /* Forms =================================================== */
  form .row.two-field {
    overflow: hidden; }
    form .row.two-field .field {
      width: 50%;
      float: left;
      padding-left: 10px; }
      form .row.two-field .field:first-child {
        padding-left: 0;
        padding-right: 10px; }
  /* Buttons =================================================== */
  /* Tables =================================================== */
  /* Layout =================================================== */
  .column-l-1-1 {
    width: 100.0%; }
  .column-l-2-1 {
    width: 50.0%; }
  .column-l-3-1 {
    width: 33.333%; }
  .column-l-3-2 {
    width: 66.666%; }
  .column-l-4-1 {
    width: 25.0%; }
  .column-l-4-3 {
    width: 75.0%; }
  .column-l-5-2 {
    width: 40.0%; }
  .column-l-5-3 {
    width: 60.0%; }
  .column-l-6-1 {
    width: 16.666%; }
  /* Main =================================================== */
  .header select {
    margin-top: 1em; }
  .sub-header select {
    margin-top: 0.25em; }
  .sub-header .select-all {
    margin-top: 0.75em; }
  /* Footer =================================================== */
  /* Pages =================================================== */
  .centre-list__item--new .centre-list__item__heading {
    font-size: 2.8em; }
  .centre-details {
    padding: 0; }
  .centre-detail {
    max-width: calc(33.3% - 2em);
    margin: 1em; }
    .centre-detail__heading {
      font-size: 1.25em; }
    .centre-detail__value {
      font-size: 6em; }
  .centre-expanded-detail {
    margin: 1em; }
  .report-buttons {
    margin-top: 32px; } }

@media only screen and (min-width: 1240px) {
  /* Page =================================================== */
  /* Typography =================================================== */
  /* Forms =================================================== */
  /* Buttons =================================================== */
  /* Tables =================================================== */
  /* Layout =================================================== */
  .column-xl-1-1 {
    width: 100.0%; }
  .column-xl-2-1 {
    width: 50.0%; }
  .column-xl-3-1 {
    width: 33.333%; }
  .column-xl-3-2 {
    width: 66.666%; }
  .column-xl-4-1 {
    width: 25.0%; }
  /* Header =================================================== */
  /* Main =================================================== */
  /* Footer =================================================== */
  /* Pages =================================================== */ }

