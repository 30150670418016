@media only screen and (min-width: 980px), screen and (min-device-width: 681px) and (max-device-width: 980px) and (min-aspect-ratio: 13/9) {
    /* Page =================================================== */    

    /* Typography =================================================== */
    h1 {
        font-size: 4em;
    }
    h2 {
        font-size: 2.8em;
    }
    h3 {
        font-size: 2em;
    }

    /* Forms =================================================== */
    form{
        .row{
            &.two-field{
                overflow: hidden;
                .field{
                    width:50%;
                    float:left;
                    padding-left:10px;
                    &:first-child{
                        padding-left:0;
                        padding-right:10px;
                    }
                }
            }
        }
    }

    /* Buttons =================================================== */

    /* Tables =================================================== */

    /* Layout =================================================== */
    .column-l-1-1{
        width: 100.0%;
    }
    .column-l-2-1{
        width: 50.0%;
    }
    .column-l-3-1{
        width: 33.333%;
    }
    .column-l-3-2{
        width: 66.666%;
    }
    .column-l-4-1{
        width: 25.0%;
    }
    .column-l-4-3{
        width: 75.0%;
    }
    .column-l-5-2{
        width: 40.0%;
    }
    .column-l-5-3{
        width: 60.0%;
    }
    .column-l-6-1{
        width: 16.666%;
    }

    /* Main =================================================== */
    .header {
        select {
            margin-top: 1em;
        }
    }

    .sub-header {
        select {
            margin-top: 0.25em;
        }
        .select-all {
            margin-top: 0.75em;
        }
    }
    
    /* Footer =================================================== */

    /* Pages =================================================== */
    .centre-list {
        &__item {
            &--new {
                .centre-list__item__heading {
                    font-size: 2.8em;
                }
            }
        }
    }

    .centre-details {
        padding: 0;
    }

    .centre-detail {
        max-width: calc(33.3% - 2em);
        margin: 1em;
        &__heading {
            font-size: 1.25em;
        }
        &__value {
            font-size: 6em;
        }
    }

    .centre-expanded-detail {
        margin: 1em;
    }

    .report-buttons {
        margin-top: 32px;
    }
}
